/**
 * Created by 胡子哥 on 2021/09/12.
 */
import {$http} from '../common/axios.js';
import {_CONFIG} from '../config/config.js';

class ServicesGlobal {
    constructor() {
        this.path = {
            api: _CONFIG.apiPath + "/boss/index.php?url=",
            www: _CONFIG.apiPath + "/www",
            apiMg: _CONFIG.api.apiThirdPath + "/mg",
            apiPublic: _CONFIG.apiPath,
        }
    }

    postParams(obj) {
        let params = new URLSearchParams();
        for (let key in obj) {
            params.append(key, obj[key]);
        }
        return params;
    }

    //获取验证码
    getImgCode(data) {
        return $http.get(`${this.path.apiPublic}/plug-in/verification/code1.php`, {params: data})
            .then(function (response) {
                return response.data;
            });
    }


    /*
    * 将excel文件转化成csv文件
    * **/
    getCsv(data={}) {
        return $http.get(`{boss}/api.php?url=tools/getCsv`,{params:data})
            .then(function (response) {
                return response.data;
            });
    }
    /*
    * 导入数据之前判断
    * **/
    importData(data={}) {
        let params = this.postParams(data);
        return $http.post(`{boss}/api.php?url=tools/import_data`, params)
            .then(function (response) {
                return response.data;
            });
    }
    /*
    * 导入数据
    * **/
    importDataStart(data={}) {
        let params = this.postParams(data);
        return $http.post(`{boss}/api.php?url=tools/up`, params)
            .then(function (response) {
                return response.data;
            });
    }
//导出数据之前获取字段
    getTableFields(data={}){
        return $http.get(`{boss}/api.php?url=export/give_fields`,{params:data})
            .then(function (response) {
                return response.data;
            });
    }
    // 导出数据接口
    exportExcel(routerName, data) {
        let exportMap = {
            "regist_table": "{boss}/api.php?url=export/exportExcel",
            "student_sign": "{boss}/api.php?url=export/exportExcel",
            "student_qingjia": "{boss}/api.php?url=export/exportExcel",

        }
        let router = exportMap[routerName]
        return $http.get(`${router}`, {params: data})
            .then(function (response) {
                return response.data;
            });
    }
    exportTemplateExcel(data={}){
        return $http.get(`{boss}/api.php?url=export/exportTemplateExcel`, {params:data})
            .then(function (response) {
                return response.data;
            });
    }
    exportTemplateExcel1(data={}){
        return $http.get(`{boss}/api.php?url=export/exportTemplateExcel1`, {params:data})
            .then(function (response) {
                return response.data;
            });
    }
    exportTemplateExcel2(data={}){
        return $http.get(`{boss}/api.php?url=export/exportTemplateExcel2`, {params:data})
            .then(function (response) {
                return response.data;
            });
    }

}

export {
    ServicesGlobal
};